<!--
 * @Author: your name
 * @Date: 2021-11-30 10:27:06
 * @LastEditTime: 2022-01-04 17:47:16
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \sproutedus\src\views\product\page1.vue
-->
<template>
  <div id="page4">
    <!--  -->
    <div class="contain contain2" data-aos="fade-up" data-aos-once="true">
      <div class="bigTitle">
        <div class="bigTitle-contain">
          <h3>小新课堂HD</h3>
          <p>
            1. 教学视频按照书本内容教学，帮助孩子对学习知识进行查漏补缺！
            <br />2. 应用内有古诗学习、诗词大汇等有趣的学习工具，让孩子在趣味中学习。
            <br />3. 全新UI界面，让家长更容易找到对孩子有帮助的课程。
            <br />4. 学习计划功能，让孩子学习更有规划、更高效率、更有针对性！
            <br />
          </p>

          <div class="appBg animated bounceIn">
            <img src="../../assets/padshow1.png" alt />
          </div>
          <!-- <div class="imgTip">
                            <img src="../../assets/221.gif" alt="" v-if="loopOpen">
          </div>-->
        </div>
      </div>
      <div class="appIntrodoce">
        <div class="appIntrodoce-logo">
          <div class="appIntrodoce-left">
            <img src="../../assets/padLogo.png" alt="app" />
          </div>
          <div class="appIntrodoce-right">
            <h3>小新课堂HD</h3>
            <p>小新课堂APP，小学生的学习辅导平台，汇聚大量优质课程，随时随地学习英语、数学、语文的课程内容，更好地帮助到孩子预习、复习和巩固学习，帮助孩子争取更好的学习成绩，让家庭教育更省心，除了学科类，更有科普、趣味知识、国学等视频学习，全面发展孩子的综合素质。</p>
          </div>
        </div>
        <div class="appIntrodoce-detail">
          <h3>应用相关详情</h3>
          <p>小新课堂APP是一款专为幼儿及小学生打造的智能手机学习应用。为孩子提供早幼教课程、帮助小学生能够随时选择不同年级科目学习，适合全国各地区的学生下载使用，能够随时切换教材内容进行学习，还有海量学科课程和素质教育课程可以播放观看，让孩子能根据自身情况预习、复习和巩固学习。应用包括了课程及学习小应用模块、学习计划模块、商城模块和用户模块，从学科、科普到素质教育类的视频一应俱全，让孩子要学的在小新课堂都能找到，让小新课堂陪伴孩子快乐成长吧!</p>
        </div>
      </div>

      <!--  -->
      <div class="section">
        <div class="sectionContain">
          <div class="sectionChild" data-aos="fade-up" data-aos-once="true">
            <div class="sectionChild-content">
              <h3>学习计划</h3>
              <p>新上线学习计划功能，既能让学生有规划性学习，也能让家长清晰掌握孩子的学习情况。家长或学生在使用“小新课堂APP”过程中可主动添加想学习的课程进入“学习计划”，在“学习计划”页中能清晰看到学习中和待开始学习的课程，课程的学习进度和每天学习的时间一目了然，家长利用学习数据进一步帮助学习调整学习频率和强度，让学习更科学地进行。</p>
            </div>
            <div class="sectionChild-img">
              <img src="../../assets/padshow2.png" alt />
            </div>
          </div>
          <div class="sectionChild" data-aos="fade-up" data-aos-once="true">
            <div class="sectionChild-img">
              <img src="../../assets/padshow3.png" alt />
            </div>
            <div class="sectionChild-content">
              <h3>VIP会员</h3>
              <p>海量课程，随时观看，同步测验，学习更高效。新人折扣优惠，打卡/任务/积分/兑换...更多超值体验等你发现！</p>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="other" data-aos="fade-up" data-aos-once="true">
        <div class="otherContain">
          <div class="otherContain-title">其他功能</div>
          <div
            class="otherContain-text"
          >小新课堂可以给3-12岁的孩子提供学科类、趣味科普类和素质教育类的教育视频观看和学习。学习计划服务能让家长更科学地安排孩子在课后的学习方向、时间和强度，追踪孩子在APP的学习进度和时长，小新课堂VIP会员，可在会员时间内无限次观看任意年级、任意学科的任意专辑，并且不设次数上限，针对VIP会员，小新课堂提供1对1课程顾问服务，在售前售后方面全方位贴身服务。</div>
          <div class="otherContain-power">
            <div class="powerItem">个人中心</div>
            <div class="powerItem">邀请好友</div>
            <div class="powerItem">兑换商城</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    name:'page4',
    data(){
        return{
            loopOpen:false,
        }
    },
    mounted(){
        this.getPlay()
    },
    destroyed(){
        clearInterval(this.time)
    },
    methods:{
        getPlay(){
            this.loopOpen = true;
            this.time = setTimeout(()=>{
                this.loopOpen = false;
                this.getPlay()
            },3000)
        },
    }
}
</script>
<style lang="scss" scoped>
.contain2 {
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.bigTitle {
  width: 100%;
  height: 500px;
  background: #4967ff;
  &-contain {
    width: 1200px;
    margin: 0 auto;
    z-index: 2;
    position: relative;
    h3 {
      font-size: 56px;
      font-weight: bold;
      line-height: 95px;
      color: #ffffff;
      padding: 96px 0 16px 30px;
    }
    p {
      width: 564px;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      margin-bottom: 28px;
      color: #ffffff;
      opacity: 0.8;
      padding-left: 30px;
      box-sizing: border-box;
    }
    ul {
      margin-top: 39px;
      padding-left: 51px;
      li {
        font-size: 24px;
        font-weight: 500;
        line-height: 41px;
        color: #ffffff;
      }
    }
    .appBg {
      position: absolute;
      top: 110px;
      right: -139px;
      width: 900px;
      height: 643px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.appIntrodoce {
  width: 1200px;
  margin: 0 auto;
  height: 340px;
  &-left {
    display: inline-block;
    width: 142px;
    height: 142px;
    margin: 39px 0 0 4px;
    vertical-align: top;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &-right {
    display: inline-block;
    width: 368px;
    margin: 66px 0 0 8px;
    vertical-align: top;
    h3 {
      font-size: 28px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #000000;
      line-height: 28px;
    }
    p {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      line-height: 27px;
      color: #5e6577;
      margin-top: 11px;
    }
  }
  &-item {
    display: inline-block;
    width: 571px;
    height: 106px;
    z-index: 3;
    margin: 34px 0 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.appOffice {
  width: 100%;
  background-color: #f4f7f9;
  padding-bottom: 98px;
  .officeContain {
    text-align: center;
    padding-top: 100px;
    h3 {
      display: inline-block;
      font-size: 38px;
      font-weight: bold;
      color: #000000;
      position: relative;
    }
    h3::before {
      position: absolute;
      top: 50%;
      left: -90px;
      display: block;
      content: "";
      background: url("../../assets/titleLeft.png") no-repeat;
      width: 74px;
      height: 3px;
      transform: translateY(50%);
    }
    h3::after {
      position: absolute;
      top: 50%;
      right: -90px;
      display: block;
      content: "";
      background: url("../../assets/titleRight.png") no-repeat;
      width: 74px;
      height: 3px;
      transform: translateY(50%);
    }
    p {
      // width: 732px;
      font-size: 32px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #5e6577;
      text-align: center;
      margin: 68px auto 0;
      b {
        font-size: 40px;
        color: #41cf14;
      }
    }
  }
  .officeContain-img {
    width: 1130px;
    height: 510px;
    margin: 100px auto 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.contain {
  animation-duration: 500ms;
}
.imgTip {
  position: absolute;
  top: 52px;
  right: -100px;
  width: 600px;
  height: 48px;
  background: transparent;
  border-radius: 24px;
  font-size: 20px;
  font-weight: bold;
  line-height: 48px;
  text-align: center;
  color: #ffffff;
  // opacity: 0.74;
  img {
    width: 100%;
    height: 100%;
  }
}
.client .bigTitle-contain {
  p {
    width: 510px;
    font-size: 19px;
  }
  .appBg {
    top: 79px;
    height: 713px;
    width: 1148px;
    right: -289px;
  }
}
.section {
  width: 100%;
  display: flex;
  justify-content: center;
  &Contain {
    .sectionChild {
      animation-duration: 500ms;
      display: flex;
      justify-content: space-between;
      &-content {
        width: 492px;
        margin-left: 36px;
        h3 {
          font-size: 28px;
          font-weight: bold;
          margin: 173px 0 36px;
          color: #1b215d;
          line-height: 28px;
        }
        p {
          font-size: 20px;
          font-weight: 400;
          line-height: 30px;
          color: #6e6e6e;
          // margin-bottom: 28px;
        }
        p:last-child {
          margin-bottom: 0;
        }
      }
      &-img {
        width: 688px;
        height: 528px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .sectionChild:nth-child(1) {
      margin: -45px 0 81px;
    }
    .sectionChild:last-child {
      margin-bottom: 191px;
      .sectionChild-content {
        h3 {
          margin: 199px 0 36px;
        }
      }
    }
  }
}

.other {
  width: 100%;
  // height: 840px;
  padding-bottom: 109px;
  background: #f4f7f9;
  display: flex;
  align-items: center;
  justify-content: center;
  &Contain {
    width: 1200px;
    text-align: center;
    padding-top: 80px;
    &-title {
      display: inline-block;
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 20px;
      color: #000000;
      text-align: center;
      position: relative;
    }
    &-title::before {
      position: absolute;
      top: 50%;
      left: -90px;
      display: block;
      content: "";
      background: url("../../assets/titleLeft.png") no-repeat;
      width: 74px;
      height: 3px;
      transform: translateY(-50%);
    }
    &-title::after {
      position: absolute;
      top: 50%;
      right: -90px;
      display: block;
      content: "";
      background: url("../../assets/titleRight.png") no-repeat;
      width: 74px;
      height: 3px;
      transform: translateY(-50%);
    }
    &-text {
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      color: #000000;
      text-align: left;
      margin-bottom: 50px;
    }
    &-power {
      width: 1200px;
      height: 507px;
      background-image: url(../../assets/show4.png);
      background-size: 100% 100%;
      display: flex;
      .powerItem {
        width: 120px;
        height: 49px;
        background-image: url(../../assets/buttonBg.png);
        background-size: 100% 100%;
        padding-top: 12px;
        box-sizing: border-box;
        text-align: center;
        font-size: 16px;
        line-height: 16px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
        margin: 4px 0 0 196px;
      }
      .powerItem:nth-child(2) {
        margin: 0 0 0 228px;
      }
      .powerItem:nth-child(3) {
        margin: 19px 0 0 233px;
      }
    }
  }
}
.section {
  width: 1200px;
  margin: 0 auto;
}
.sectionContain {
  width: 100%;
  .sectionChild {
    width: 100%;
  }
}
.sectionContain .sectionChild-content h3 {
  color: #000000;
  margin: 22px 0 36px;
}
.sectionContain .sectionChild-img {
  width: 524px;
  height: 394px;
}
.sectionContain .sectionChild:last-child .sectionChild-content h3 {
  margin: 140px 0 36px;
}
.sectionContain .sectionChild-content {
  margin-left: 0;
}
.otherContain-text {
  margin-bottom: 155px;
}
.otherContain-power {
  background-image: url("../../assets/padshow4.png");
  width: 1230px;
  height: 419px;
}
.otherContain-power .powerItem {
  margin: -69px 0 0 217px;
}
.otherContain-power .powerItem:nth-child(2) {
  margin: -69px 0 0 236px;
}
.otherContain-power .powerItem:nth-child(3) {
  margin: -69px 0 0 200px;
}
.client .appIntrodoce {
  height: auto;
}
.appIntrodoce-logo {
  width: 100%;
  .appIntrodoce-right {
    p {
      display: block;
    }
  }
}
.appIntrodoce-detail {
  margin-top: 67px;
  width: 100%;
  padding-bottom: 180px;
  h3 {
    font-size: 24px;
    font-weight: bold;
    color: #000000;
  }
  p {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #6e6e6e;
  }
}
</style>